import React from "react";
import { Row, Col } from "react-bootstrap";


const Footer = () => {
  return (
    <Row>
      <Col style={{ backgroundColor: "black" }}>
        <footer>
          <Row>
            <Col align="center">
              <a style={{ color: "white" }} href={"mailto:info@supply24.se"}>
                <i className="fa fa-envelope" style={{ color: "grey" }}>
                  &nbsp; Email
                </i>
              </a>

              <a style={{ color: "white" }} href={"mailto:info@supply24.se"}>
                <small style={{ color: "grey", paddingLeft: 20 }}>
                  info@supply24.se
                </small>
              </a>
            </Col>
          </Row>
          <br></br>
          <Row>
            <Col align="center">
              <p style={{ color: "white" }}>Operations in Gothenburg, Sweden</p>
            </Col>
          </Row>
          <Row>
            <Col
              md={{ span: 2, offset: 5 }}
              sm={{ span: 2, offset: 5 }}
              lg={{ span: 2, offset: 5 }}
              xl={{ span: 2, offset: 5 }}
              align="center"
              style={{ color: "white" }}
            >
               supply24.se

            </Col>
            <br></br>{" "}
          </Row>
        </footer>
      </Col>
    </Row>
  );
};

export default Footer;
