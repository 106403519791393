import { Navbar, Col, Row, Container } from "react-bootstrap";
import React from "react";
const Header = () => {
  return (
    <Row>
      <header>
        <Navbar bg="black">
          <Col
            md={{ span: 2, offset: 0 }}
            sm={{ span: 2, offset: 0 }}
            lg={{ span: 2, offset: 0 }}
            xl={{ span: 2, offset: 0 }}
            align="left" style={{ padding: 20 }}
          >
            <img
              style={{}}
              src="/images/logo1.png"
              height="120px"
              width="120px"
              alt=""
            />
          </Col>







        </Navbar>
      </header>
    </Row>
  );
};

export default Header;
